import React from 'react'
import { Navbar, Nav, NavDropdown, MenuItem } from 'react-bootstrap'

import { useFileUpload } from 'use-file-upload'
import { csvParse } from 'd3-dsv'
import { EXAMPLES } from '../views/app.constants'

const VEGA_LITE_DOCS = 'https://vega.github.io/vega-lite/docs/'

const headerText = <span style={{ color: '#a3afbf' }}>Vega Visual Editor</span>

interface HeaderProps {
  history: any // WithRouter injected history
  handleData: (data: any) => void // pass new uploads into root component
}

const Header: React.FC<HeaderProps> = props => {
  const [_, selectFile] = useFileUpload()

  return (
    <Navbar inverse staticTop fluid style={{ border: 0 }}>
      <Navbar.Header>
        <Navbar.Brand>
          <a href={VEGA_LITE_DOCS} target="_blank" rel="noopener noreferrer">
            {/* TODO... determine whether to bring in a new logo or use the Vega-Lite SVG */}
            {/* <img
                alt="builder logo"
                src={logo}
                style={{
                  height: 30,
                  marginTop: 13,
                  width: 30,
                  marginLeft: '-0.5rem'
                }}
              /> */}
          </a>
        </Navbar.Brand>
        <Navbar.Text style={{ marginLeft: '0.75rem' }}>
          {headerText}
        </Navbar.Text>
      </Navbar.Header>
      <Navbar.Text pullRight>
        <Navbar.Link href={VEGA_LITE_DOCS}>Docs</Navbar.Link>
      </Navbar.Text>
      <Nav pullRight>
        <NavDropdown eventKey={3} title="Import" id="data-import-dropdown">
          <MenuItem
            style={{ cursor: 'pointer' }}
            onClick={() => {
              const dataUrl = window.prompt('Paste data URL here')
              if (dataUrl) {
                const encodedUrl = encodeURIComponent(dataUrl)
                // use react router to change url
                props.history.push({
                  pathname: '/',
                  search: `?dataUrl=${encodedUrl}`
                })
              }
            }}
          >
            Import remote file (via URL)
          </MenuItem>
          <MenuItem
            onClick={() => {
              selectFile(
                { accept: '.csv, application/json', multiple: false },
                payload => {
                  if (!Array.isArray(payload)) {
                    if (payload.file.type === 'text/csv') {
                      payload.file.text().then(text => {
                        const data = csvParse(text)
                        // strip out columns property
                        props.handleData(data.map(row => row))
                      })
                    } else if (payload.file.type === 'application/json') {
                      payload.file.text().then(text => {
                        const data = JSON.parse(text)
                        if (props.handleData) {
                          props.handleData(data)
                        }
                      })
                    } else {
                      // warn user that file is not CSV or JSON
                      window.alert('File is not a CSV or JSON')
                    }
                  }
                }
              )
            }}
          >
            Import local file (CSV/JSON)
          </MenuItem>

          <MenuItem divider />
          {/* Load examples */}
          {EXAMPLES.map(example => (
            <MenuItem
              key={example.name}
              onClick={() => {
                props.history.push({
                  pathname: '/',
                  search: `?dataUrl=${example.url}`
                })
              }}
            >
              Load example: {example.name}
            </MenuItem>
          ))}
        </NavDropdown>
      </Nav>
    </Navbar>
  )
}

export default Header
