import './index.css'
import 'regenerator-runtime/runtime'

import { createBrowserHistory } from 'history'
import { Provider } from 'mobx-react'
import React from 'react'
import ReactDOM from 'react-dom'
import { Route, Router, Switch } from 'react-router-dom'

import { unregister } from './registerServiceWorker'
import Store from './util/Store'
import App from './views/App'

const history = createBrowserHistory()

const store = Store.create({
  token: '',
  config: {
    encodings: []
  }
})
store.addBrowserHistoryListener(history)

const rootElement = document.getElementById('root')
if (rootElement) {
  ReactDOM.render(
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route path="/">
            <App />
          </Route>
        </Switch>
      </Router>
    </Provider>,
    rootElement
  )
}

unregister()
