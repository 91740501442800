import { csv, json } from 'd3-fetch'
import { runInAction } from 'mobx'
import { inject, observer } from 'mobx-react'
import React, { Component, Fragment } from 'react'
import { ButtonToolbar, Col, Grid, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

// import loading animation
import LoadingAnimation from '../components/LoadingAnimation'
import DownloadButton from '../components/DownloadButton'
import Header from '../components/Header'
import ResizableVegaLiteEmbed from '../components/ResizableVegaLiteEmbed'

import Sidebar from '../components/Sidebar'
import VizCard from '../components/VizCard'
import VizEmpty from '../components/VizEmpty'
import classes from './App.module.css'
import { withRouter } from 'react-router'
import { EXAMPLES } from './app.constants'

// import { helloWorld } from './utils'

// helloWorld()

// type AppP = {
//   history: Object,
//   location: Object,
//   store: StoreType
// }

// TODO: use a more robust type inference library
// A table is an array of objects with the same keys.
// A schema is a list of fields with a name and a vegaType (naming to be explicit)
// to assist with refactoring w/o help of typescript
const getSchemaFromRows = table => {
  // To simplify, we assume every row has the same keys.
  const fieldNames = Object.keys(table[0])

  const detectedFields = fieldNames.map(col => {
    // Check if either every value is a number or is coercable to a number
    if (
      table.every(r => typeof r[col] === 'number' || !Number.isNaN(+r[col]))
    ) {
      return { name: col, vegaType: 'quantitative', rdfType: '' }
    }

    if (table.every(r => typeof r[col] === 'boolean')) {
      return { name: col, vegaType: 'nominal', rdfType: '' }
    }

    // TODO: add date field inference.
    return { name: col, vegaType: 'nominal', rdfType: '' }
  })
  // console.table(detectedFields)
  // TODO: decide whether to drop column

  return detectedFields
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
      loading: false
    }
  }

  // data: ?Array<Object> = null

  // loading: boolean = true
  // errorLoading: boolean = false
  // bytesDownloaded: number = 0

  componentDidMount() {
    // Initial fetch
    if (this.props.store.parsedUrlQuery.dataUrl) {
      this.fetchQuery()
    }

    // Clean up memory on unmount
    this.unlisten = this.props.history.listen((location, action) => {
      // console.log('changed', location);
      if (location.search) {
        this.fetchQuery()
      }
    })
  }

  componentWillUnmount() {
    this.unlisten()
  }

  getQueryHeaders() {
    const { store } = this.props
    return {
      Accept:
        store.queryType === 'sql'
          ? 'application/json'
          : 'application/sparql-results+json',
      Authorization: `Bearer ${store.token}`,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  }

  fetchQuery = async () => {
    const { store } = this.props
    runInAction(() => {
      store.setFields([])
      this.setState({ data: null, loading: true })
    })

    // Fetch CSV from URL parameters
    const DEFAULT_REMOTE_DATA =
      'https://rawcdn.githack.com/vega/vega-datasets/49d64cdd589ab655704443fa66509416ec35bb43/data/penguins.json'
    // backup: https://rawcdn.githack.com/vega/vega-datasets/49d64cdd589ab655704443fa66509416ec35bb43/data/wheat.json

    // Check if dataURL ends in CSV or json to choose with function to fetch with
    const dataURL = new URL(store.parsedUrlQuery.dataUrl || DEFAULT_REMOTE_DATA)
    const isCsv = dataURL.pathname.endsWith('.csv')

    const data = await (isCsv ? csv(dataURL.href) : json(dataURL.href))
    // console.log({ data })
    this.handleData(data)
  }

  processData(rows) {
    // processData(rows: Array<Object>) {
    // TODO: need to write some schema inference code
    if (Array.isArray(rows)) {
      const fields = getSchemaFromRows(rows)
      return {
        fields,
        rows
      }
    }
  }

  // handleData = (data: Array<Object>) => {
  handleData = data => {
    const { store } = this.props

    // reset store up front!
    this.props.store.reset()

    const { fields, rows } = this.processData(data)
    // console.log({ fields, rows })

    runInAction(() => {
      store.setFields([
        ...fields.map(f => ({
          name: f.name,
          rdfType: f.rdfType,
          vegaType: f.vegaType
        })),
        {
          name: '*',
          label: 'COUNT(*)',
          rdfType: 'http://www.w3.org/2001/XMLSchema#integer',
          vegaType: 'quantitative'
        }
      ])
      // store.setRows(rows);
      this.setState({
        data: rows,
        loading: false
      })
      // if (this.props.store.config.encodings.length === 0) {
      //   this.props.store.reset()
      // }
    })
  }

  // vegaView: Object
  handleViewRender = v => {
    this.vegaView = v
  }

  renderEmbed() {
    const { data } = this.state
    const { store } = this.props

    return (
      (data &&
        store.fields &&
        store.config.hasPossiblyValidChart && (
          <ResizableVegaLiteEmbed
            spec={store.config.generatedSpec}
            data={data}
            onViewRender={this.handleViewRender}
            setDimensions={store.config.setDimensions}
            showResize={
              !store.config.hasManualSpec && !store.config.hasFacetField
            }
          />
        )) || <VizEmpty />
    )
  }

  render() {
    const { store, history } = this.props
    let bodyContent = null
    if (!store.hasDataToRender) {
      bodyContent = (
        <Grid style={{ marginTop: 32 }}>
          <Row>
            <Col xs={12}>
              <h3>Use "import" to add a dataset via a remote or local file</h3>
              {EXAMPLES.map(({ name, url, description }) => (
                <div key={name}>
                  <Link
                    to={{
                      pathname: '/',
                      search: `?dataUrl=${url}`
                    }}
                  >
                    <p>{description}</p>
                  </Link>
                </div>
              ))}
            </Col>
          </Row>
        </Grid>
      )
    } else if (this.state.loading || this.errorLoading) {
      bodyContent = (
        <Grid style={{ marginTop: 32 }}>
          {this.state.loading ? (
            <LoadingAnimation hideOverlay label={'Downloading...'} />
          ) : (
            <h4>Error loading data</h4>
          )}
        </Grid>
      )
    } else {
      bodyContent = (
        <div className={classes.main}>
          {this.state.data && <Sidebar data={this.state.data ?? []} />}
          <div className={classes.embed}>
            <Grid fluid className={classes.topBar}>
              <Row>
                <Col xs={12} className={classes.topBarCol}>
                  <div className={classes.topBarHeader}>
                    <input
                      data-test="chart-title"
                      className={classes.topBarTitle}
                      placeholder="Untitled chart"
                      value={store.config.title || ''}
                      onChange={e => {
                        store.config.setTitle(e.target.value)
                      }}
                    />
                  </div>
                  <div className={classes.topBarButtons}>
                    <ButtonToolbar>
                      <DownloadButton
                        getVegaView={() => this.vegaView}
                        getData={() => this.data}
                      />
                    </ButtonToolbar>
                  </div>
                </Col>
              </Row>
            </Grid>
            <VizCard>{this.renderEmbed()}</VizCard>
          </div>
        </div>
      )
    }

    return (
      <Fragment>
        <Header handleData={this.handleData} history={history} />
        {bodyContent}
      </Fragment>
    )
  }
}

export default withRouter(inject('store')(observer(App)))
