import React, { Component } from 'react'

import classes from './SidebarFooter.module.css'

class SidebarFooter extends Component {
  render() {
    return (
      <div className={classes.bottomBar}>
        <div>
          <a
            href="https://vega.github.io/vega-lite/docs/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Vega-Lite Docs
          </a>
        </div>
      </div>
    )
  }
}
export default SidebarFooter
