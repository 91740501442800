export const EXAMPLES = [
  {
    name: 'Penguins',
    url:
      'https://rawcdn.githack.com/vega/vega-datasets/49d64cdd589ab655704443fa66509416ec35bb43/data/penguins.json',
    description: 'Penguins dataset'
  },
  {
    name: 'Wheat',
    url:
      'https://rawcdn.githack.com/vega/vega-datasets/49d64cdd589ab655704443fa66509416ec35bb43/data/wheat.json',
    description: 'Wheat dataset'
  }
]
